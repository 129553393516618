const templateVariantSettings = {
  header: "DESKTOP",
  homePage: "HomeTemplateV5",
  websiteName: "Idaho United CU",
  speedBump: {
    borderWithRadious: true,
  },
  about: null,
  contact: null,
  footer: null,
};

module.exports = templateVariantSettings;
